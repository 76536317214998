import React from "react";
import MainSection from "../MainSection";
import { MENU_ITEMS } from "../../constants";
import BigCardSection from "../BigCardsSection";
import SmallCardSection from "../SmallCardsSection";
import CoinsPng from "../../coins.png";

function Tokenomics() {
  return (
    <MainSection className="container" id={MENU_ITEMS.TOKENOMICS}>
      <h2 className={`title text-center 2xl:leading-tight}`}>
        The token fuels large-scale adoption of water reuse systems to save
        billions of cubic meters in the world
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12 sm:mt-20">
        <BigCardSection />
      </div>

      <div className="top-spacing">
        <div className={`wtr-gradient`}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <SmallCardSection />
          </div>
          <h2 className={`mt-12 text-center pb-6 2xl:leading-tight space-y-10`}>
            <div className={`text-wtr-500 title font-black`}>
              Trade with a purpose
            </div>
            <div className="text-2xl">
              BTC, AI and other world-changing technologies are demanding the
              planet a massive toll. Join the revolution of regenerative
              tokenization.
            </div>
          </h2>
        </div>
      </div>

      <div className="md:mt-24">
        <div className="text-gray-200 md:flex md:items-center md:space-x-10">
          <img src={CoinsPng} alt="Coins" className="w-full md:w-1/2 mx-auto" />
          <div className="w-full md:w-1/2 flex flex-col items-center">
            <ol className="list-disc ml-4 md:text-lg space-y-4">
              <li className="font-thin">
                Symbol: <span className="font-bold">WTR</span>
              </li>
              <li className="font-thin">
                Total Supply: <span className="font-bold">1B</span>
              </li>
              <li className="font-thin">
                Listing Value: <span className="font-bold">2.2USD</span>
              </li>
              <li className="font-thin">
                Pre-sale Price: <span className="font-bold">2USD</span>
              </li>
              <li className="font-thin">
                Type: <span className="font-bold">ASA</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </MainSection>
  );
}

export default Tokenomics;
