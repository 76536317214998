import React from "react";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

function Popup({ onClose, title, children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-secondary-700/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        className="bg-secondary-400 text-white w-full md:max-w-xl p-5 rounded-xl space-y-5"
        initial={{ marginBottom: -100, opacity: 0 }}
        animate={{ marginBottom: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          ease: "easeOut",
        }}
      >
        <div className="flex w-full justify-between">
          <div className="text-2xl font-bold">{title}</div>
          <IoClose className="size-6 cursor-pointer" onClick={onClose} />
        </div>
        {children}
      </motion.div>
    </div>
  );
}

export default Popup;
