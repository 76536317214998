import { ID_RESOURCE } from "../constants";
import fetcher from "./fetcher";
import useSWR from "swr";

export function reserveTokens(name, email, qty) {
  return fetcher(`/token/${ID_RESOURCE}/reserve`, {
    method: "post",
    data: { name, email, qty },
  });
}

export function sendMessage(name, email, message) {
  return fetcher(`/presale/mail`, {
    method: "post",
    data: { name, email, message },
  });
}

export const useCoins = (options) => {
  const { data, error } = useSWR(
    `/presale/data`,
    (url) => fetcher(url, { method: "get" }),
    options
  );

  return {
    data,
    error,
    isLoading: !data && !error,
  };
};
