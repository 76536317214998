import { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { useAppStore } from "../stores/appStore";

const CountDownItem = ({
  size,
  radius,
  value,
  label,
  max,
  valueClassName,
  labelClassName,
}) => {
  return (
    <div>
      {value === max ? (
        <motion.div
          style={{ width: size, height: size }}
          className="text-wtr-500 absolute flex flex-col justify-center items-center"
          animate={{ rotateX: 90 }}
          transition={{
            duration: 0.3,
            ease: "easeIn",
            repeat: 1,
            repeatType: "reverse",
          }}
        >
          <div className={valueClassName || ""}>{value}</div>
          <div className={labelClassName || ""}>{label}</div>
        </motion.div>
      ) : (
        <div
          style={{ width: size, height: size }}
          className="text-wtr-500 absolute flex flex-col justify-center items-center"
        >
          <div className={valueClassName || ""}>{value}</div>
          <div className={labelClassName || ""}>{label}</div>
        </div>
      )}
      <motion.svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={(size - radius) / 2}
          stroke="#1f2937"
          strokeWidth={radius}
          fillOpacity={0}
        />
        <motion.circle
          cx={size / 2}
          cy={size / 2}
          r={(size - radius) / 2}
          stroke="#00ccff"
          strokeWidth={radius}
          fillOpacity={0}
          animate={{
            pathLength: value / max,
            rotateZ: 360 * (value / max),
          }}
          transition={{
            duration: 1,
          }}
        />
      </motion.svg>
    </div>
  );
};

export default function CountDown({ startDate, endDate }) {
  const [size, setSize] = useState();
  const [radius, setRadius] = useState();
  const [valueClassName, setValueClassName] = useState();
  const [labelClassName, setLabelClassName] = useState();
  const [showItems, setShowItems] = useState();
  const [items, setItems] = useState();
  const { windowWidth } = useAppStore((state) => state);

  const setCountdown = useCallback(
    (_startDate, _endDate) => {
      const now = new Date();
      if (_endDate < now) setItems();
      else {
        var delta = Math.abs(_endDate - now) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        const seconds = Math.floor(delta % 60);

        const totalDelta = Math.abs(_startDate - _endDate) / 1000;
        var totalDays = Math.floor(totalDelta / 86400);

        setItems([
          { label: "days", value: days, max: totalDays },
          { label: "hours", value: hours, max: 23 },
          { label: "minutes", value: minutes, max: 59 },
          { label: "seconds", value: seconds, max: 59 },
        ]);
      }
    },
    [setItems]
  );

  useEffect(() => {
    let _radius = 2;
    let _size = 50;
    let _valueClassName = "";
    let _labelClassName = "";
    if (windowWidth > 1500) {
      _size = 110;
      _radius = 8;
      _valueClassName = "text-4xl";
      _labelClassName = "text-xs";
    } else if (windowWidth > 1300) {
      _size = 100;
      _radius = 6;
      _valueClassName = "text-3xl";
      _labelClassName = "text-xs";
    } else if (windowWidth > 900) {
      _size = 90;
      _radius = 6;
      _valueClassName = "text-4xl";
      _labelClassName = "text-xs";
    } else if (windowWidth > 50) {
      _size = 70;
      _radius = 4;
      _valueClassName = "text-3xl";
      _labelClassName = "text-[10px]";
    }

    setRadius(_radius);
    setSize(_size);
    setValueClassName(_valueClassName);
    setLabelClassName(_labelClassName);

    setShowItems(false);
    setTimeout(() => {
      setShowItems(true);
    }, 100);
  }, [windowWidth]);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const fixDate = (date) => {
      let dt = date.split(" ");
      const time = dt[1].split(":");
      dt = dt[0].split("/");

      return new Date(`${dt[2]}/${dt[1]}/${dt[0]} ${time[0]}:${time[1]}`);
    };

    const _startDate = fixDate(startDate);
    const _endDate = fixDate(endDate);

    setCountdown(_startDate, _endDate);

    const interval = setInterval(() => {
      setCountdown(_startDate, _endDate);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate, setCountdown]);

  return (
    showItems &&
    radius &&
    items && (
      <div className="flex space-x-3">
        {items.map((item, itemKey) => {
          return (
            <CountDownItem
              key={`countdown-item-${itemKey}`}
              size={size}
              radius={radius}
              value={item.value}
              label={item.label}
              max={item.max}
              labelClassName={labelClassName}
              valueClassName={valueClassName}
            />
          );
        })}
      </div>
    )
  );
}
