export const ID_RESOURCE = 1;

export const MENU_ITEMS = {
  HOME: "home",
  BUYNOW: "reserve",
  //ADOPTERS: "adopters",
  //SOURCES: "sources",
  TOKENOMICS: "tokenomics",
  ROADMAP: "roadmap",
  TEAM: "team",
  FAQ: "FAQ",
};

export const adopters = [
  {
    pic: "https://1000logos.net/wp-content/uploads/2020/03/Kappa-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/02/timberland-logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2017/10/Louis-Vuitton-Logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2017/01/Adidas-Logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/07/Los-Angeles-Rams-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2019/06/Buffalo-Bills-logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2019/06/Philadelphia-Eagles-Logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2019/06/Dallas-Cowboys-Logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2017/03/McDonalds-logo-tumb-1.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/05/Gmail-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2017/08/Snapchat-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2017/02/Instagram-Logo-tumb.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2020/04/Mahindra-Logo-tumb.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/04/mercedes-logo-sm.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/02/BMW-Logo-500x281-1.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2018/02/Ford-Logo-tumb.png",
    url: "",
  },
];

export const sources = [
  {
    pic: "https://1000logos.net/wp-content/uploads/2019/06/Apple-Logo.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Good-Housekeeping-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Pirates-of-the-Caribbean-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Williams-Sonoma-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Affirm-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Neiman-Marcus-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Pacsun-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/ClickFunnels-Logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2024/02/Wattpad-logo-tumb.jpg",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-ATT-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-USA-Today-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-Minolta-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-Westinghouse-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-General-Electric-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-Safari-500x281.png",
    url: "",
  },
  {
    pic: "https://1000logos.net/wp-content/uploads/2021/11/logo-Volkswagen-500x281.png",
    url: "",
  },
];

export const team = [
  {
    name: "Pietro Gorgazzini",
    pic: "/team/pietro.png",
    url: "",
  },
  {
    name: "Alan Torrisi",
    pic: "/team/alan.jpg",
    url: "",
  },
  {
    name: "Antonio Luigi Fabio Gerli",
    pic: "/team/antonio.jpg",
    url: "",
  },
  {
    name: "Michele Novembre",
    pic: "/team/michele.jpg",
    url: "",
  },
  {
    name: "Oscar di Montigny",
    pic: "/team/oscar.webp",
    url: "",
  },
  {
    name: "Eleonora del Plato",
    pic: "/team/eleonora.jpg",
    url: "",
  },
  {
    name: "Jacopo Gerli",
    pic: "/team/jacopo.jpg",
    url: "",
  },
  {
    name: "Ettore Battaglini",
    pic: "/team/ettorecrop.jpg",
    url: "",
  },
  {
    name: "Lars Schlichting",
    pic: "/team/lars.jpg",
    url: "",
  },
  {
    name: "Gherardo De Angelis",
    pic: "/team/gherardo.jpg",
    url: "",
  },
  // {
  //   name: "Andre Bryant",
  //   pic: "https://images.pexels.com/photos/25758/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Amie Vance",
  //   pic: "https://images.pexels.com/photos/769772/pexels-photo-769772.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Yash Pennington",
  //   pic: "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Catherine George",
  //   pic: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Barbara Reid",
  //   pic: "https://images.pexels.com/photos/774095/pexels-photo-774095.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Aishah Webb",
  //   pic: "https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Mabel Navarro",
  //   pic: "https://images.pexels.com/photos/7699365/pexels-photo-7699365.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Courtney Odonnell",
  //   pic: "https://images.pexels.com/photos/7699305/pexels-photo-7699305.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Leena Gallegos",
  //   pic: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
  // {
  //   name: "Juliette Newman",
  //   pic: "https://images.pexels.com/photos/842980/pexels-photo-842980.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   url: "",
  // },
];

export const roadmapGoals = [
  {
    label: "July 2023",
    text: "We’ve incorporated in Switzerland and received Finma nal for our tokenomics.",
  },
  {
    label: "September 2023",
    text: "The first cubic meter of reclaimed water has been tokenized from a source.",
  },
  {
    label: "January 2024",
    text: "First companies started buying WTR to offset their water consumption.",
  },
  {
    label: "April 2024",
    text: "Listing the WTR on BitMart enabling anyone to join our journey.",
  },
  {
    label: "Q3/Q4 2024",
    text: "Listing on multiple Exchanges to tap into more liquidity and expand.",
  },
];

export const faqs = [
  {
    label: "How is the WTR linked to water?",
    text: "We connect via APIs to flowmeters, pumps and counters recording real-time data from water reuse facilities. Each cubic meter being reclaimed, triggers the issuing of a WTR.",
  },
  {
    label: "What is a water credit?",
    text: "Water credits, just like carbon credits, are a tool for companies to offset environmental debt (water consumption, in this case) in their yearly ESG reports.",
  },
  {
    label: "Is Algorand energy efficient?",
    text: "We chose Algorand to build our ecosystem as it’s world’s first carbon negative public blockchain. Proof of stake is also less energy intensive compared to proof of work.",
  },
];
