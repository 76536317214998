export default function FooterSection({ items }) {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {items.map((item, itemKey) => {
          return (
            <div key={`footer-item-${itemKey}`}>
              <h3 className="uppercase">{item.title}</h3>
              <div className="mt-4 flex flex-col space-y-2 text-xs">
                {item.links ? (
                  item.links.map((link, linkKey) => {
                    return (
                      <a
                        key={`footer-item-${itemKey}-link-${linkKey}`}
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.label}
                      </a>
                    );
                  })
                ) : (
                  <div>{item.content}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="p-8 text-center border-t border-gray-600 mt-12 text-xs">
        Copyright © 2023-2024 Hypercube SA | All rights reserved
      </div>
    </>
  );
}
