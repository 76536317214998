import axios from "axios";
//import { getCookie } from "./cookies";

class Fetcher {
  apiClient;
  fetcher;

  constructor(baseURL, interceptor) {
    this.apiClient = axios.create({
      baseURL,
    });

    this.apiClient.interceptors.request.use(interceptor);

    this.fetcher = async (url, conf) => {
      const { method, data, params } = conf;

      return new Promise((resolve, reject) => {
        this.apiClient
          .request({ method, url, data, params })
          .then((res) => resolve(res.data.data))
          .catch((err) => reject(getErrorMsgFromServer(err)));
      });
    };
  }
}

const fetcher = new Fetcher(process.env.REACT_APP_BASE_URL, (req) => {
  //const userToken = getCookie(process.env.NEXT_PUBLIC_COOKIE_NAME);

  req.headers = {
    Accept: "application/json",
  };

  //if (userToken) req.headers["Token"] = userToken;
  //else req.headers["Server-Token"] = process.env.NEXT_PUBLIC_SERVER_TOKEN;
  req.headers["Server-Token"] = process.env.REACT_APP_SERVER_TOKEN;

  return req;
}).fetcher;

function getErrorMsgFromServer(error) {
  const details = error?.response?.data?.error?.details;
  return details && typeof details.join == "function"
    ? details.join(" ").trim()
    : details;
}

export const getQueryParamsArray = (params) => {
  return Object.keys(params)
    .reduce((result, name) => {
      if (params[name] !== null)
        result.push(`${name}=${encodeURIComponent(params[name])}`);
      return result;
    }, [])
    .join("&");
};

export default fetcher;
