import { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { motion } from "framer-motion";
import * as THREE from "three";

export default function TopSection({ children }) {
  const [isFirstAccess, setIsFirstAccess] = useState(true);

  useEffect(() => {
    if (!isFirstAccess) return;
    setIsFirstAccess(false);

    const SEPARATION = 100,
      AMOUNTX = 50,
      AMOUNTY = 50;

    let container;
    let camera, scene, renderer;

    let particles,
      count = 0;

    init();
    animate();

    function init() {
      container = document.getElementById("waves");

      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      camera.position.z = 2600;

      scene = new THREE.Scene();

      const numParticles = AMOUNTX * AMOUNTY;

      const positions = new Float32Array(numParticles * 3);
      const scales = new Float32Array(numParticles);

      let i = 0,
        j = 0;

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2; // x
          positions[i + 1] = 0; // y
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2; // z

          scales[j] = 1;

          i += 3;
          j++;
        }
      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute("scale", new THREE.BufferAttribute(scales, 1));

      const material = new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(0xffffff) },
        },
        vertexShader: document.getElementById("vertexshader").textContent,
        fragmentShader: document.getElementById("fragmentshader").textContent,
      });

      particles = new THREE.Points(geometry, material);
      scene.add(particles);

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setClearColor(0xffffff, 0);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      //container.style.touchAction = "none";

      window.addEventListener("resize", onWindowResize);
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function animate() {
      requestAnimationFrame(animate);
      render();
    }

    function render() {
      camera.position.x += camera.position.x * 0.05;
      //camera.position.y += (-mouseY - camera.position.y) * 0.05;
      camera.position.y = 100;
      //camera.lookAt(scene.position);

      const positions = particles.geometry.attributes.position.array;
      const scales = particles.geometry.attributes.scale.array;

      let i = 0,
        j = 0;

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i + 1] =
            Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50;

          scales[j] =
            (Math.sin((ix + count) * 0.3) + 1) * 2 +
            (Math.sin((iy + count) * 0.5) + 1) * 2;

          i += 3;
          j++;
        }
      }

      particles.geometry.attributes.position.needsUpdate = true;
      particles.geometry.attributes.scale.needsUpdate = true;

      renderer.render(scene, camera);

      count += 0.01;
    }
  }, [isFirstAccess]);

  return (
    <>
      {/* <div
        className="fixed w-full h-full top-0 left-0 -z-10"
        style={{
          background: `radial-gradient(
            ellipse farthest-corner at right top,
            rgba(9, 14, 38, 1) 0%,
            rgba(8, 20, 40, 1) 100%
          )`,
        }}
      ></div> */}
      <div id="waves">{children}</div>
      <div className="absolute bottom-4 md:bottom-20 left-0 w-full flex justify-center">
        <motion.div
          animate={{
            opacity: [0, 0, 0, 1, 0, 0, 0],
            marginTop: [-80, -80, -80, -40, 0, 0, 0],
          }}
          transition={{
            duration: 4,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          <MdKeyboardDoubleArrowDown className="text-4xl" />
        </motion.div>
      </div>
    </>
  );
}
