import React from "react";
import { MENU_ITEMS } from "../../constants";
import MainSection from "../MainSection";
import TopSection from "../TopSection";
import CountDown from "../CountDown";

function Intro() {
  return (
    <MainSection id={MENU_ITEMS.HOME} className="!pt-0">
      <TopSection>
        <div
          className={`container flex flex-col md:flex-row h-screen justify-center md:justify-between items-center`}
        >
          <div>
            <div
              className={`subtitle text-center md:text-left mt-10 md:mt-0 leading-none`}
            >
              <h1 className="text-wtr-500 font-black">The impact token</h1>
              <p className="my-3">backed by reclaimed water</p>
              <p>from water reuse facilities.</p>
            </div>
            <div className="mt-6 md:mt-12 flex w-full justify-center sm:justify-start">
              <CountDown
                startDate="10/02/2024 10:00"
                endDate="31/03/2024 00:00"
              />
            </div>
          </div>
          <div>
            <div
              className={`relative mt-8 md:sm-0 w-[250px] h-[250px] sm:w-[400px] sm:h-[400px]
              `}
            >
              <img
                className="absolute z-10"
                src="/rotating-token/logo-big.gif"
                alt="rotating token"
                width={500}
              />
            </div>
          </div>
        </div>
      </TopSection>
    </MainSection>
  );
}

export default Intro;
