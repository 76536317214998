export default function VerticalPeopleSection({ items }) {
  return (
    <div className="flex space-x-2 pb-3 w-full overflow-x-auto">
      {items.map((item, itemKey) => {
        return (
          <div
            key={`people-${itemKey}`}
            className="flex flex-col items-center justify-center !size-[300px] space-y-4 py-4 px-7 bg-secondary-400 rounded-xl"
          >
            <div
              className="p-20 bg-center bg-no-repeat bg-cover rounded-full"
              style={{ backgroundImage: `url(${item.pic})` }}
            ></div>
            <div>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
}
