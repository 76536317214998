import CardSmall from "./CardSmall";
import { BiSolidDollarCircle } from "react-icons/bi";
import { IoIosWater } from "react-icons/io";
import { FaLeaf } from "react-icons/fa";

export default function SmallCardSection() {
  return (
    <>
      <CardSmall
        title="Real market value of  the underlying water credit"
        icon={<BiSolidDollarCircle />}
      />
      <CardSmall
        title="Real environmental impact for each token"
        icon={<IoIosWater />}
      />
      <CardSmall title="Green-washing proof by design" icon={<FaLeaf />} />
    </>
  );
}
