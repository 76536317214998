import React from "react";
import MainSection from "../MainSection";
import { MENU_ITEMS, roadmapGoals } from "../../constants";
import RoadMapSection from "../RoadMapSection";

function Roadmap() {
  return (
    <MainSection id={MENU_ITEMS.ROADMAP}>
      <h2 className={`text-center mb-12 subtitle`}>our roadmap</h2>
      <RoadMapSection goals={roadmapGoals} accomplishedGoals={3} />
    </MainSection>
  );
}

export default Roadmap;
