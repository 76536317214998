export default function Documents({ items }) {
  return (
    <>
      <div className="flex flex-col w-full space-y-3">
        {items.map((item, itemKey) => {
          return item.url ? (
            <a
              key={`document-${itemKey}`}
              href={item.url}
              target="_blank"
              rel="noreferrer"
            >
              <Document item={item} />
            </a>
          ) : (
            <Document key={`document-${itemKey}`} item={item} />
          );
        })}
      </div>
    </>
  );
}

function Document({ item }) {
  return (
    <div className="bg-white/10 text-xs p-4 rounded-xl flex w-full space-x-1 items-center cursor-pointer hover:opacity-90">
      {item.icon}
      <div>{item.label}</div>
    </div>
  );
}
