import Header from "../components/Header";
import Intro from "../components/sections/Intro";
import Supply from "../components/sections/Supply";
import Tokenomics from "../components/sections/Tokenomics";
import Roadmap from "../components/sections/Roadmap";
import Team from "../components/sections/Team";
import Faq from "../components/sections/Faq";
import Footer from "../components/Footer";
import Message from "../components/Message";

function Home() {
  return (
    <div>
      <Header />
      <Intro />
      <Supply />
      <Tokenomics />
      <Roadmap />
      <Team />
      <Faq />
      <Footer />
      <GlobalElements />
    </div>
  );
}

function GlobalElements() {
  return <Message />;
}

export default Home;
