import React from "react";
import MainSection from "../MainSection";
import PeopleSection from "../PeopleSection";
import { MENU_ITEMS, team } from "../../constants";
import VerticalPeopleSection from "../VerticalPeopleSection";

function Team() {
  return (
    <MainSection id={MENU_ITEMS.TEAM}>
      <h2 className={`text-center mb-12 subtitle`}>our team</h2>
      <div className="hidden xl:block">
        <PeopleSection items={team} />
      </div>
      <div className="block xl:hidden">
        <div className={`container`}>
          <VerticalPeopleSection items={team} isVertical={true} />
        </div>
      </div>
    </MainSection>
  );
}

export default Team;
