import React, { useState } from "react";
import MainSection from "../MainSection";
import ProgressionSection from "../ProgressionSection";
import Button from "../Button";
import { RxCaretRight } from "react-icons/rx";
import ReservePopup from "../ReservePopup";
import { MENU_ITEMS } from "../../constants";
import { useAppStore } from "../../stores/appStore";
import { MESSAGE_TYPES } from "../Message";
import { sendMessage } from "../../services/services";

function Supply() {
  const [openReservePopup, setOpenReservePopup] = useState(false);
  const { setMessage } = useAppStore((state) => state);

  return (
    <MainSection className="container" id={MENU_ITEMS.BUYNOW}>
      <ProgressionSection min={0} max={1000000}>
        <div className="flex space-x-1 sm:space-x-4 justify-center mt-16">
          <Button
            className="text-black text-xs bg-white"
            innerClassName="py-3 px-4 sm:px-8"
            bgColor="rgba(255, 255, 255, 0)"
            clickColor="rgba(0, 204, 255, 1)"
            label={
              <div className="w-full flex justify-center items-center space-x-2">
                <div>Buy tokens</div> <RxCaretRight className="text-xl" />
              </div>
            }
            onClick={() => {
              setOpenReservePopup(true);
            }}
          />
          <a href="/docs/white-paper.pdf" target="_blank">
            <Button
              className="text-white text-xs bg-secondary-400"
              innerClassName="py-3 px-4 sm:px-8"
              bgColor="rgba(31, 41, 55, 0)"
              clickColor="rgba(0, 204, 255, 1)"
              label={
                <div className="w-full flex justify-center items-center space-x-2">
                  <div>White paper</div> <RxCaretRight className="text-xl" />
                </div>
              }
            />
          </a>
        </div>
      </ProgressionSection>
      {openReservePopup ? (
        <ReservePopup
          onClose={() => setOpenReservePopup(false)}
          onReserve={(name, email, qty) => {
            sendMessage(name, email, `Ordine: ${qty}`).then(() => {
              setOpenReservePopup(false);
              setMessage(MESSAGE_TYPES.CONFIRM_RESERVE);
            });
          }}
        />
      ) : null}
    </MainSection>
  );
}

export default Supply;
