import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { motion } from "framer-motion";

export default function FaqSection({ items }) {
  const [selectedItem, setSelectedItem] = useState();

  return (
    <div className="flex flex-col space-y-4">
      {items.map((item, itemKey) => {
        return (
          <div
            className={`${
              selectedItem === itemKey
                ? "bg-wtr-500 text-gray-800"
                : "bg-secondary-500"
            } p-8 flex w-full flex-col rounded-xl cursor-pointer hover:opacity-90`}
            key={`faq-key-${itemKey}`}
            onClick={() =>
              setSelectedItem(itemKey === selectedItem ? null : itemKey)
            }
          >
            <div className="w-full flex justify-between items-center">
              <div>{item.label}</div>
              {selectedItem === itemKey ? <FaCaretUp /> : <FaCaretDown />}
            </div>
            {selectedItem === itemKey && (
              <motion.div
                className={`text-sm mt-4 ${
                  selectedItem === itemKey ? "text-gray-600" : "text-gray-400"
                }`}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {item.text}
              </motion.div>
            )}
          </div>
        );
      })}
    </div>
  );
}
