import React from "react";
import FooterSection from "./FooterSection";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div className="top-spacing">
      <div className={`container pt-12`}>
        <div className="wtr-gradient">
          <FooterSection
            items={[
              {
                title: "Useful Links",
                links: [
                  { label: "Hypercube.eco", url: "https://hypercube.eco" },
                  {
                    label: "Info token",
                    url: "https://hypercube.eco/token/1",
                  },
                ],
              },
              {
                title: "Boring stuff",
                links: [
                  {
                    label: "Terms & Conditions",
                    url: "https://hypercube.eco/legal",
                  },
                ],
              },
              {
                title: "Follow the mission on",
                content: (
                  <div className="flex space-x-5">
                    <a href="https://t.me/wateract">
                      <FaTelegramPlane className="w-5 h-5" />
                    </a>
                    <a href="https://twitter.com/wtrtoken">
                      <FaXTwitter className="w-5 h-5" />
                    </a>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
