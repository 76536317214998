import React from "react";
import MainSection from "../MainSection";
import { MENU_ITEMS, faqs } from "../../constants";
import FaqSection from "../FaqSection";
import Form from "../Form";
import Documents from "../Documents";
import { FaFilePdf, FaVideo } from "react-icons/fa";

function Faq() {
  return (
    <MainSection className="container" id={MENU_ITEMS.FAQ}>
      <div className="top-spacing">
        <h2 className={`subtitle text-center mb-12`}>quick answers</h2>
        <FaqSection items={faqs} />
      </div>

      <div className="top-spacing">
        <h2 className={`subtitle text-center`}>wanna know more?</h2>
        <div className="flex flex-col lg:flex-row w-full space-y-4 lg:space-x-4 lg:space-y-0 mt-12">
          <div className="bg-secondary-400 rounded-xl p-4 w-full lg:w-1/2 xl:w-2/3">
            <h3 className="text-2xl text-center py-4 mb-4">Get In Touch</h3>
            <Form />
          </div>
          <div className="bg-secondary-400 rounded-xl p-4 w-full lg:w-1/2 xl:w-1/3">
            <h3 className="text-2xl text-center py-4 mb-4">Documents</h3>
            <Documents
              items={[
                {
                  label: "I wanna read a lot",
                  url: "/docs/white-paper.pdf",
                  icon: <FaFilePdf />,
                },
                // {
                //   label: "Don’t feel like reading much",
                //   url: "",
                //   icon: <FaFilePdf />,
                // },
                {
                  label: "I wanna watch a webinar",
                  url: "",
                  icon: <FaVideo />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </MainSection>
  );
}

export default Faq;
