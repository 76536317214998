import { motion } from "framer-motion";

export default function ConfirmEmail() {
  const pathVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: (i) => {
      const delay = i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
    exit: {
      pathLength: 0,
      opacity: 0,
      transition: {
        pathLength: { type: "spring", duration: 1, bounce: 0 },
        opacity: { duration: 1 },
      },
    },
  };
  return (
    <div className="flex flex-col space-y-2 items-center justify-center w-[200px] text-center">
      <motion.svg
        stroke="currentColor"
        fill="currentColor"
        viewBox="-0.5 0 25 25"
        height="100px"
        width="100px"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <motion.path
          variants={pathVariants}
          custom={0.5}
          fill="none"
          d="M9.00977 21.39H19.0098C20.0706 21.39 21.0881 20.9685 21.8382 20.2184C22.5883 19.4682 23.0098 18.4509 23.0098 17.39V7.39001C23.0098 6.32915 22.5883 5.31167 21.8382 4.56152C21.0881 3.81138 20.0706 3.39001 19.0098 3.39001H7.00977C5.9489 3.39001 4.93148 3.81138 4.18134 4.56152C3.43119 5.31167 3.00977 6.32915 3.00977 7.39001V12.39"
        />
        <motion.path
          d="M1.00977 18.39H11.0098"
          variants={pathVariants}
          custom={1.3}
        />
        <motion.path
          d="M1.00977 15.39H5.00977"
          variants={pathVariants}
          custom={1}
        />
        <motion.path
          fill="none"
          d="M22.209 5.41992C16.599 16.0599 9.39906 16.0499 3.78906 5.41992"
          variants={pathVariants}
          custom={0.5}
        />
      </motion.svg>
      <div className="font-bold text-lg">your message has been delivered</div>
    </div>
  );
}
