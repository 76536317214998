import { useState, useCallback } from "react";
import { RxCaretRight } from "react-icons/rx";
import Button from "./Button";
import Input from "./Input";
import { sendMessage } from "../services/services";
import { MESSAGE_TYPES } from "./Message";
import { useAppStore } from "../stores/appStore";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const { setMessage } = useAppStore((state) => state);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      sendMessage(name, email, msg).then(() =>
        setMessage(MESSAGE_TYPES.CONFIRM_CONTACT)
      );
    },
    [name, email, msg]
  );

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col w-full h-full text-sm space-y-2"
    >
      <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-x-2 lg:space-y-0">
        <div className="w-full">
          <Input
            required={true}
            labelMarginTop="hidden lg:block -mt-8"
            value={name}
            type="text"
            labelClassName="text-gray-400"
            className="w-full bg-white/10 rounded-xl text-white"
            label="Name"
            onChange={(value) => {
              setName(value);
            }}
          />
        </div>
        <div className="w-full">
          <Input
            required={true}
            labelMarginTop="hidden lg:block -mt-8"
            value={email}
            type="email"
            labelClassName="text-gray-400"
            className="w-full bg-white/10 rounded-xl text-white"
            label="Email"
            onChange={(value) => {
              setEmail(value);
            }}
          />
        </div>
      </div>
      <textarea
        className="w-full p-2 rounded-xl bg-white/10 h-[150px] text-white"
        placeholder="Message"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
      ></textarea>

      <Button
        className="text-black text-xs bg-white my-6 w-[200px] mx-auto"
        type="submit"
        innerClassName="py-3 px-4 sm:px-8"
        bgColor="rgba(255, 255, 255, 0)"
        clickColor="rgba(0, 204, 255, 1)"
        label={
          <div className="w-full flex justify-center items-center space-x-2">
            <div>Send Message</div> <RxCaretRight className="text-xl" />
          </div>
        }
        onClick={() => {}}
      />
    </form>
  );
}
