import { useState, useCallback } from "react";
import { motion } from "framer-motion";

const PeopleItem = ({ selectedItem, itemKey, item }) => {
  return (
    <>
      <div
        className={`rounded-xl w-full h-full bg-no-repeat bg-center bg-cover ${
          selectedItem !== itemKey && "opacity-10"
        }`}
        style={{ backgroundImage: `url(${item.pic})` }}
      ></div>
      <div className={`w-full h-full flex flex-col items-center -mt-[400px]`}>
        <div className="grow flex items-end">
          <h2
            className={`text-2x ${
              selectedItem !== itemKey
                ? "-rotate-90 origin-bottom-left text-nowrap absolute pl-4 ml-3"
                : "mb-6 bg-black/20 p-2"
            }`}
          >
            {item.name}
          </h2>
        </div>
        {selectedItem !== itemKey && (
          <div
            className="w-[100%] pb-[100%] bg-cover bg-center bg-no-repeat rounded-full"
            style={{ backgroundImage: `url(${item.pic})` }}
          ></div>
        )}
      </div>
    </>
  );
};

export default function PeopleSection({ items }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [lastSelectedItem, setLastSelectedItem] = useState();
  const [isFirstClick, setIsFirstClick] = useState(true);

  const onClick = useCallback(
    (index) => {
      setSelectedItem(index);
      if (isFirstClick) setIsFirstClick(false);
      else setLastSelectedItem(selectedItem);
    },
    [
      isFirstClick,
      selectedItem,
      setSelectedItem,
      setLastSelectedItem,
      setIsFirstClick,
    ]
  );

  return (
    <div
      className={`flex space-x-2 justify-center ${
        selectedItem !== null ? "h-[400px]" : "h-[400px]"
      }`}
    >
      {items.map((item, itemKey) => {
        return selectedItem === itemKey || lastSelectedItem === itemKey ? (
          <motion.div
            key={`people-${itemKey}`}
            className="grow h-full"
            initial={{ maxWidth: selectedItem === itemKey ? 100 : 300 }}
            animate={{
              width: selectedItem === itemKey ? 300 : 100,
              maxWidth: selectedItem === itemKey ? 300 : 100,
            }}
            transition={{
              duration: 0.3,
              ease: "easeIn",
            }}
            onAnimationComplete={() => setLastSelectedItem()}
            onClick={() => setSelectedItem(itemKey)}
          >
            <PeopleItem
              selectedItem={selectedItem}
              item={item}
              itemKey={itemKey}
            />
          </motion.div>
        ) : (
          <div
            key={`people-${itemKey}`}
            className="grow h-full hover:opacity-90 cursor-pointer max-w-[100px]"
            onClick={() => onClick(itemKey)}
          >
            <PeopleItem
              selectedItem={selectedItem}
              item={item}
              itemKey={itemKey}
            />
          </div>
        );
      })}
    </div>
  );
}
