import { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { useCoins } from "../services/services";

export default function ProgressionSection({ children, min, max }) {
  const { data: coins } = useCoins({ refreshInterval: 30000 });

  return (
    <>
      <div className="text-center mt-12 sm:mt-0">
        <div
          className={`subtitle flex space-x-2 items-center mb-16 justify-center`}
        >
          <Counter amount={coins?.token_left} />
          <span>tokens left</span>
        </div>

        <div className="h-[20px] md:h-[30px]">
          <div className="h-full rounded-full bg-secondary-400 border-4 border-gray-800/20 overflow-hidden">
            <motion.div
              className="h-full rounded-full bg-wtr-500 w-1/2"
              initial={{ width: "100%" }}
              whileInView={{
                width: `${(coins?.token_left * 100) / max}%`,
              }}
              transition={{
                duration: 1,
                ease: "easeOut",
              }}
              viewport={{ once: true }}
            ></motion.div>
          </div>
        </div>
        <div className="flex w-full justify-between text-sm mt-2">
          <div>{new Intl.NumberFormat("EN-en").format(min)}</div>
          <div>{new Intl.NumberFormat("EN-en").format(max)}</div>
        </div>
      </div>
      {children}
    </>
  );
}

export function Counter({ amount }) {
  const intervalRef = useRef(null);
  const counterRef = useRef(null);
  const [num, setNum] = useState(0);
  const isInView = useInView(counterRef, { once: true });

  useEffect(() => {
    if (typeof amount === "undefined" || !isInView) return;

    const step = parseInt(amount / 100);
    setNum(0);
    intervalRef.current = setInterval(() => {
      setNum((state) => {
        if (state + step >= amount) {
          clearInterval(intervalRef.current);
          return amount;
        }

        return state + step;
      });
    }, 10);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [amount, isInView]);

  return (
    <div
      ref={counterRef}
      className={
        "font-bold text-wtr-500 min-w-[162px] text-right text-2xl sm:text-4xl 2xl:text-5xl"
      }
    >
      {new Intl.NumberFormat("EN-en").format(num)}
    </div>
  );
}
