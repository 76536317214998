import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppStore } from "../stores/appStore";
import ConfirmEmail from "./Messages/ConfirmEmail";
import ConfirmReserve from "./Messages/ConfirmReserve";

export const MESSAGE_TYPES = {
  CONFIRM_CONTACT: 1,
  CONFIRM_RESERVE: 2,
};

const MESSAGE_CONTENTS = {
  1: ConfirmEmail,
  2: ConfirmReserve,
};

export default function Message() {
  const overlayVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { opacity: { duration: 0.3, ease: "easeInOut" } },
    },
    exit: {
      opacity: 0,
      transition: { opacity: { duration: 1.5, ease: "easeInOut" } },
    },
  };

  const containerVariants = {
    initial: { marginBottom: -100, opacity: 0 },
    animate: {
      marginBottom: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    exit: {
      marginBottom: -100,
      opacity: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const { message, setMessage } = useAppStore((state) => state);

  useEffect(() => {
    if (message)
      setTimeout(() => {
        setMessage(null);
      }, 3000);
  }, [message]);

  return (
    <AnimatePresence>
      {message ? (
        <motion.div
          variants={overlayVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="fixed top-0 left-0 w-full h-full bg-secondary-700/80 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        >
          <motion.div variants={containerVariants}>
            {MESSAGE_CONTENTS[message]()}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
