import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";
import logoBigPng from "../logo_big.png";
import Menu from "./Menu";
import { MENU_ITEMS } from "../constants";

// const menuItems = [
//   {
//     id: "home",
//     label: "Home",
//   },
//   {
//     id: "menu-1",
//     label: "Adopters",
//   },
//   {
//     id: "menu-2",
//     label: "Sources",
//   },
//   {
//     id: "menu-3",
//     label: "Tokenomics",
//   },
//   {
//     id: "menu-4",
//     label: "Roadmap",
//   },
//   {
//     id: "menu-5",
//     label: "FAQ",
//   },
// ];

export default function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState();

  const menuItems = Object.values(MENU_ITEMS).map((item) => ({
    id: item,
    label: item,
  }));

  return (
    <>
      <div
        className={`w-full fixed top-0 left-0 p-2 z-50 bg-secondary-500/80 backdrop-blur-sm`}
      >
        <div className={`container flex justify-between items-center`}>
          <img src={"/header_logo.png"} className="w-[200px] mt-2" alt="Logo" />
          <Menu className="hidden lg:flex lg:space-x-12" items={menuItems} />
          <GiHamburgerMenu
            className="block lg:hidden text-2xl"
            onClick={() => setShowMobileMenu(true)}
          />
        </div>
      </div>

      {showMobileMenu && (
        <div className="fixed top-0 left-0 w-full h-screen z-50 flex justify-end bg-black/50">
          <motion.div
            className="bg-[#04060f] flex flex-col overflow-x-hidden border-l border-gray-800/20"
            initial={{
              width: 0,
              opacity: 0,
            }}
            animate={{ width: (window.innerWidth * 90) / 100, opacity: 1 }}
            transition={{
              duration: 0.5,
              type: "easeOut",
            }}
          >
            <div className="w-[90vw] py-8 px-4 h-full overflow-y-auto">
              <div className="flex w-full justify-between">
                <div className="grow flex w-full justify-center">
                  <img src={logoBigPng} alt="Logo" className="mb-6" />
                </div>
                <IoClose onClick={() => setShowMobileMenu(false)} />
              </div>
              <Menu
                className="flex flex-col space-y-6 p-4"
                items={menuItems}
                onItemClick={() => setShowMobileMenu(false)}
              />
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}
