import { FaHandHoldingWater } from "react-icons/fa";
import CardBig from "./CardBig";
import { BiSolidDollarCircle } from "react-icons/bi";
import { TbRefresh } from "react-icons/tb";

export default function BigCardSection() {
  return (
    <>
      <CardBig
        title="Rewards sustainable companies"
        text="for each cubic meter of wastewater they can treat and reuse."
        icon={<BiSolidDollarCircle />}
      />
      <CardBig
        title="Enables offsetting water debt"
        text="of large water intensive companies committed to reduce their footprint."
        icon={<TbRefresh />}
      />
      <CardBig
        title="Funds water-positive projects"
        text="in areas of the world suffering severe water stress."
        icon={<FaHandHoldingWater />}
      />
    </>
  );
}
