import { create } from "zustand";

export const useAppStore = create((set) => ({
  windowWidth: null,
  selectedMenuItem: null,
  message: null,

  setWindowWidth: (windowWidth) => {
    set({ windowWidth });
  },
  setSelectedMenuItem: (selectedMenuItem) => {
    set({ selectedMenuItem });
  },
  setMessage: (message) => {
    set({ message });
  },
}));
