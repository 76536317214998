import { useAppStore } from "../stores/appStore";

export default function Menu({ className, items, onItemClick }) {
  const { selectedMenuItem } = useAppStore((state) => state);

  return (
    <div className={`${className || ""}`}>
      {items.map((item, itemKey) => {
        return (
          <a key={`menu-item-${itemKey}`} href={`#${item.id}`}>
            <div
              key={`menu-item-${itemKey}`}
              className={`capitalize ${
                selectedMenuItem === item.id
                  ? "text-wtr-500"
                  : "cursor-pointer hover:opacity-50"
              }`}
              onClick={() => {
                if (typeof onItemClick === "function") onItemClick();
              }}
            >
              {item.label}
            </div>
          </a>
        );
      })}
    </div>
  );
}
