import React, { useRef, useState } from "react";
import Input from "./Input";
import Button from "./Button";
import Popup from "./Popup";

function ReservePopup({ onClose, onReserve }) {
  const formRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [qty, setQty] = useState(0);

  const onSubmit = (e) => {
    e.preventDefault();
    onReserve(name, email, qty);
  };

  return (
    <Popup title="Reserve WTR" onClose={onClose}>
      <form
        onSubmit={onSubmit}
        ref={formRef}
        className="flex flex-col w-full h-full text-sm space-y-10"
      >
        <div className="text-slate-400">
          You can request to reserve a certain amount of tokens from those
          available, and you will soon be contacted by one of our experts.
        </div>
        <div className="w-full">
          <Input
            required={true}
            labelMarginTop="-mt-8"
            type="text"
            labelClassName="text-gray-400"
            className="w-full bg-white/10 rounded-xl text-white"
            label="Name"
            onChange={(value) => {
              setName(value);
            }}
            value={name}
          />
        </div>
        <div className="w-full">
          <Input
            required={true}
            labelMarginTop="-mt-8"
            type="email"
            labelClassName="text-gray-400"
            className="w-full bg-white/10 rounded-xl text-white"
            label="Email"
            onChange={(value) => {
              setEmail(value);
            }}
            value={email}
          />
        </div>
        <div className="w-full">
          <Input
            required={true}
            labelMarginTop="-mt-8"
            type="number"
            labelClassName="text-gray-400"
            className="w-full bg-white/10 rounded-xl text-white"
            label="Quantity"
            onChange={(value) => {
              setQty(value);
            }}
            value={qty}
          />
        </div>

        <Button
          className="text-black text-xs bg-white my-6 w-[200px] mx-auto"
          type="submit"
          innerClassName="py-3 px-4 sm:px-8"
          bgColor="rgba(255, 255, 255, 0)"
          clickColor="rgba(0, 204, 255, 1)"
          label={
            <div className="w-full flex justify-center items-center space-x-2">
              <div>Reserve</div>
            </div>
          }
        />
      </form>
    </Popup>
  );
}

export default ReservePopup;
