//import HomePage from "./pages/HomePage";
import { useEffect } from "react";
import "./App.css";
import Home from "./pages/Home";
import { useAppStore } from "./stores/appStore";

function App() {
  const { setWindowWidth } = useAppStore((state) => state);

  useEffect(() => {
    const getWindowSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", getWindowSize);
    getWindowSize();

    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
  }, []);

  return <Home />;
}

export default App;
