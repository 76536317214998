import { useState, useEffect, forwardRef } from "react";

const Input = forwardRef(function Input(
  {
    value,
    disabled,
    label,
    required,
    className,
    labelClassName,
    type,
    labelMarginTop,
    onChange,
    onFocus,
    onBlur,
  },
  fieldRef
) {
  const [fieldValue, setFieldValue] = useState("");
  const [isFieldFocused, setIsFieldFocused] = useState();

  useEffect(() => {
    if (typeof onChange === "function")
      onChange(fieldValue, fieldRef?.current?.checkValidity());
  }, [fieldValue, fieldRef, onChange]);

  useEffect(() => {
    if (!value) return;
    setFieldValue(value);
  }, [value]);

  return (
    <>
      <label
        className={`absolute transition-all select-none p-2 pointer-events-none ${
          isFieldFocused || fieldValue ? labelMarginTop || "-mt-12" : ""
        } ${labelClassName ? labelClassName : ""}`}
      >
        {label}
        {required && "*"}
      </label>
      <input
        ref={fieldRef}
        type={type}
        required={required}
        className={`${className} p-2 ${disabled ? "opacity-50" : ""}`}
        value={fieldValue}
        disabled={disabled}
        spellCheck={false}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsFieldFocused(true);
        }}
        onBlur={() => {
          setIsFieldFocused(false);
          onBlur && onBlur();
        }}
        onChange={(e) => setFieldValue(e.target.value)}
      />
    </>
  );
});

export default Input;
