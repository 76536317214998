import { useCallback, forwardRef } from "react";
import { motion, useAnimationControls } from "framer-motion";

const ButtonChild = ({ label, className, bgColor, animate, transition }) => {
  return (
    <motion.div
      className={`w-full h-full flex items-center ${
        className ? className : ""
      }`}
      style={{
        background: bgColor,
      }}
      animate={animate}
      transition={transition}
    >
      {label}
    </motion.div>
  );
};

const Button = forwardRef(function Button(
  {
    disabled,
    className,
    innerClassName,
    type,
    bgColor,
    clickColor,
    label,
    onClick,
  },
  buttonRef
) {
  const mainControls = useAnimationControls();
  const buttonControls = useAnimationControls();

  const onButtonClick = useCallback(
    (e) => {
      mainControls.start({
        scale: [0.95, 1],
      });

      if (clickColor) {
        buttonControls.start({
          background: [
            `radial-gradient(
          circle,
          ${clickColor} 0%,
          ${bgColor} 1%,
          ${bgColor} 100%
        )`,
            `radial-gradient(
          circle,
          ${clickColor} 0%,
          ${clickColor} 100%,
          ${clickColor} 100%
        )`,
            `radial-gradient(
          circle,
          ${bgColor} 0%,
          ${bgColor} 100%,
          ${bgColor} 100%
        )`,
          ],
        });
      }
      if (typeof onClick === "function") onClick(e);
    },
    [buttonControls, mainControls, clickColor, bgColor, onClick]
  );

  return (
    <div className="flex flex-col justify-end">
      <motion.button
        ref={buttonRef}
        disabled={disabled}
        type={type || "button"}
        className={`rounded-full overflow-hidden ${
          disabled ? "opacity-20" : ""
        } ${className}`}
        onClick={onButtonClick}
        initial={{ scale: 1 }}
        animate={mainControls}
        transition={{
          duration: 0.2,
          ease: "easeIn",
        }}
      >
        <div className="flex flex-col justify-center relative">
          <ButtonChild
            label={label}
            bgColor={bgColor}
            className={innerClassName}
            animate={buttonControls}
            transition={{
              duration: 0.5,
              ease: "easeIn",
            }}
          />
        </div>
      </motion.button>
    </div>
  );
});

export default Button;
