import { motion } from "framer-motion";

export default function ConfirmReserve() {
  const pathVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: (i) => {
      const delay = i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
    exit: {
      pathLength: 0,
      opacity: 0,
      transition: {
        pathLength: { type: "spring", duration: 1, bounce: 0 },
        opacity: { duration: 1 },
      },
    },
  };

  return (
    <div className="flex flex-col space-y-2 items-center justify-center w-[200px] md:w-[300px] text-center">
      <motion.svg
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
        height="100px"
        width="100px"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <motion.path
          variants={pathVariants}
          custom={1.2}
          d="M8.5 12.5L10.5 14.5L15.5 9.5"
        />
        <motion.path
          d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.3378"
          variants={pathVariants}
          custom={0.5}
        />
      </motion.svg>
      <div className="font-bold text-lg">
        Your reservation request has been sent
      </div>
    </div>
  );
}
