import { useInView } from "framer-motion";
import { createContext, useEffect, useRef } from "react";
import { useAppStore } from "../stores/appStore";

export const sectionContext = createContext();

export default function MainSection({ children, id, className }) {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { margin: "-100px 0px -100px 0px" });

  const { setSelectedMenuItem } = useAppStore((state) => state);

  useEffect(() => {
    if (isInView && id) setSelectedMenuItem(id);
  }, [isInView, setSelectedMenuItem, id]);

  return (
    <sectionContext.Provider value={{ isInView, id }}>
      <div
        id={id}
        className={`sm:min-h-screen flex flex-col justify-center pt-[80px] sm:py-20 ${className}`}
        ref={sectionRef}
      >
        {children}
      </div>
    </sectionContext.Provider>
  );
}
