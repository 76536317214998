import { useRef, useEffect, useState } from "react";
import { useAppStore } from "../stores/appStore";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import Popup from "./Popup";

export default function RoadMapSection({ goals, accomplishedGoals }) {
  const [items, setItems] = useState([]);
  const [popoupValue, setPopupValue] = useState();
  const { windowWidth } = useAppStore((state) => state);
  const containerRef = useRef();
  const lineRef = useRef();

  const accomplishedPercent = (accomplishedGoals * 100) / goals.length;

  useEffect(() => {
    if (!lineRef?.current) return;

    const totalLength = lineRef.current.getTotalLength();
    const ratio = windowWidth / 210;
    const _items = [];
    const partLength = totalLength / goals.length;

    goals.forEach((goal, index) => {
      const point = lineRef.current.getPointAtLength(partLength * (index + 1));
      _items.push({
        top: point.y * ratio,
        left: point.x * ratio,
      });
    });

    setItems(_items);
  }, [lineRef, goals, windowWidth]);

  const [lineProgress, setLineProgress] = useState(0);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset:
      windowWidth > 500 ? ["start start", "end end"] : ["start end", "end end"],
  });

  useMotionValueEvent(scrollYProgress, "change", (progress) => {
    setLineProgress(() =>
      accomplishedPercent > progress * 100
        ? progress
        : accomplishedPercent / 100
    );
  });

  return (
    <>
      {popoupValue && (
        <Popup onClose={() => setPopupValue()} title={popoupValue.label}>
          <div>{popoupValue.text}</div>
        </Popup>
      )}
      <div className="text-center py-[100px] sm:mt-0" ref={containerRef}>
        {items.map((item, itemKey) => {
          return (
            <RoadMapItem
              item={item}
              key={`roadmap-goal-${itemKey}`}
              goal={goals[itemKey]}
              windowWidth={windowWidth}
              setPopupValue={setPopupValue}
              isActive={
                lineProgress * 100 < ((itemKey + 1) * 100) / items.length
              }
            />
          );
        })}
        <motion.svg viewBox={`0 0 210 210`} xmlns="http://www.w3.org/2000/svg">
          <motion.path
            ref={lineRef}
            stroke="#555555"
            fill="none"
            d="m 204.95554,1.7148142 c 0,0 -125.143468,12.6870068 -86.53262,44.4181688 40.72316,33.467102 1.40333,40.13007 -41.386449,44.872606 -17.983781,1.942441 -36.254397,2.821777 -53.091673,10.009941 -42.39902,19.01655 -10.460006,51.34534 29.14757,55.22555 34.556821,3.3854 61.790442,-30.08511 83.022372,-40.47221 34.92126,-17.084203 54.66718,-1.08499 62.21881,19.23857 18.65043,52.52947 -29.73851,48.64951 -65.0805,40.58014 -15.79485,-3.36272 -37.749316,-4.66905 -49.883663,9.37606 -20.395934,23.60761 -40.791873,25.04459 -71.094407,19.50194"
          />
        </motion.svg>
        <div style={{ marginTop: -windowWidth + (windowWidth > 500 ? 5 : 0) }}>
          <motion.svg
            viewBox={`0 0 210 210`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              animate={{ pathLength: lineProgress }}
              // transition={{
              //   duration: 0.1,
              //   ease: "easeInOut",
              // }}
              className="indicator"
              stroke="#00ccff"
              fill="none"
              d="m 204.95554,1.7148142 c 0,0 -125.143468,12.6870068 -86.53262,44.4181688 40.72316,33.467102 1.40333,40.13007 -41.386449,44.872606 -17.983781,1.942441 -36.254397,2.821777 -53.091673,10.009941 -42.39902,19.01655 -10.460006,51.34534 29.14757,55.22555 34.556821,3.3854 61.790442,-30.08511 83.022372,-40.47221 34.92126,-17.084203 54.66718,-1.08499 62.21881,19.23857 18.65043,52.52947 -29.73851,48.64951 -65.0805,40.58014 -15.79485,-3.36272 -37.749316,-4.66905 -49.883663,9.37606 -20.395934,23.60761 -40.791873,25.04459 -71.094407,19.50194"
            />
          </motion.svg>
        </div>
      </div>
    </>
  );
}

function RoadMapItem({ item, goal, windowWidth, setPopupValue, isActive }) {
  const size = windowWidth > 500 ? 40 : 20;

  return (
    <div
      className={`absolute rounded-full transition-colors ${
        isActive ? "bg-zinc-600" : "bg-wtr-500"
      }
              `}
      style={{
        width: size,
        height: size,
        marginTop: item.top - size / 2,
        marginLeft: item.left - size / 2,
      }}
    >
      <div
        className={`absolute rounded-xl transition-colors ${
          windowWidth > 500
            ? "mt-12 w-[300px]"
            : "text-xs mt-6 min-w-[100px] text-center"
        } text-left bg-black/40 p-2 
        ${isActive ? "opacity-50" : "bg-wtr-500 opacity-90"}`}
      >
        <div
          className=""
          onClick={() => {
            windowWidth < 500 && setPopupValue(goal);
          }}
        >
          {/* {windowWidth < 500 && <FaCaretDown />} */}
          <div className="font-bold">{goal.label}</div>
        </div>
        {windowWidth > 500 && <div>{goal.text}</div>}
      </div>
    </div>
  );
}
